<template>
    <div>
        <div class="table-container">
            <!-- хлебные крошки-->
            <div class="filter-breadcrumb">
                <span class="item-breadcrumb" v-if="period === 0" >{{ 'Дата' }} - {{ dateTo}}</span>
                <span class="item-breadcrumb" v-if="reg !== null && reg_name !== null" >{{ reg }} - {{ reg_name }}</span>
                <span class="item-breadcrumb" v-if="year !== null" >{{'Год'}} - {{ year }}</span>
                <span class="item-breadcrumb" v-if="month !== null" >{{ month }}</span>
            </div>
        </div>
        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="unReport"
                responsive="true"
                bordered
                head-variant="light"
                no-border-collapse
                sticky-header="true">

                <template #cell(num)="data">
                    <div v-if="!data.item._rowVariant">
                        {{ data.index + 1 }}
                    </div>
                </template>
                <template #cell(plan_year)="data">
                    <div>
                        {{ $n(data.item.plan_year) }}
                    </div>
                </template>
                <template #cell(plan)="data">
                    <div>
                        {{ $n(data.item.plan) }}
                    </div>
                </template>
                <template #cell(fact)="data">
                    <div>
                        {{ $n(data.item.fact) }}
                    </div>
                </template>
                <template #cell(delta1)="data">
                    <div>
                        {{ $n(data.item.delta1) }}
                    </div>
                </template>
                <template #cell(delta2)="data">
                    <div>
                        {{ $n(data.item.delta2) }}
                    </div>
                </template>

            </b-table>
        </div>
        <div class="actions-tab in-tab">
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content >
                    <div class="btn-download">
                        <div>
                                        <span class="left">
                                            <i class="icon icon-download"></i> Скачать
                                        </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item v-if="unReport.length > 0" @click="downloadRep()">{{repName}}</b-dropdown-item>
            </b-dropdown>
        </div>
        <div>
            <loading
                :active="loading"
                is-full-screen
                spinner="bar-fade-scale"
                color="#6495ED"
            />
        </div>
    </div>
</template>

<script>
import {Ax} from '@/utils';
import moment from 'moment';
import VueElementLoading from "vue-element-loading";
export default {
    name: 'report-income',
    components: {'loading': VueElementLoading},
    data() {
        return {
            reg: null,
            year: 2023,
            dateTo: null,
            month: null,
            curParams: {},
            period: null,
            repCode: 'income',
            repName: 'Исполнения доходной части',
            overReport: [],
            unReport: [],
            loading: false,
            convert: '',
            hasData: false,
            tableFields: [
                {
                    key: 'num',
                    label: '№'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование районов и городов'
                },
                {
                    key: 'plan_year',
                    label: 'План на 2023 год'
                },
                {
                    key: 'plan',
                    label: 'План на отчетный период'
                },
                {
                    key: 'fact',
                    label: 'Фактическое поступление '
                },
                {
                    key: 'delta1',
                    label: '% исполнения'
                },
                {
                    key: 'delta2',
                    label: 'Отклонение '
                }
            ]
        }
    },
    created() {
        this.$on('report-income', (reg, reg_name, period, date_to, month, year, obl, convert) => {
            this.reportOpen(reg, period, date_to, month, year, obl, convert);
            this.reg = reg;
            this.year = year;
            this.period = period
            this.month = month;
            this.reg_name = reg_name;
            this.dateTo = moment(date_to).format('YYYY-MM-DD');
            this.convert = convert;
            // this.tableFields[2].label = `План на ${year} год`;
        });
    },
    methods: {
        async reportOpen(reg, period, date_to, month, year, obl, convert) {
            this.loading = true;
            const utcTimestamp = date_to;
            const utcDate = new Date(utcTimestamp);
            utcDate.setDate(utcDate.getDate() + 1);
            const newUtcTimestamp = utcDate.toISOString();
            this.curParams = {
                region: reg,
                period: period,
                date_to: newUtcTimestamp,
                month: month,
                year: year,
                report_id: this.report_id,
                form: this.repCode,
                obl: obl,
                user_id: this.$store.getters.user_uuid,
                convert: convert
            };
            if (period === 0) {
                const y = date_to.getFullYear()
                this.tableFields[2].label = `План на ${y} год`;
            }
            else if (period === 1) {
                this.tableFields[2].label = `План на ${year} год`;
            }

            if (reg && period > -1 && date_to && year && obl) {
                Ax(
                    {
                        url: `/api-py/get-fulldata-income/` + JSON.stringify(this.curParams),
                        method: 'GET'
                    },
                    (data) => {
                        this.unReport = data.unReport;
                        this.overReport = data.overReport;
                        this.unReport.sort((a, b) => a.sort_name.localeCompare(b.sort_name));
                        for (const d of this.unReport) {
                            if (d.fact > 0) {
                                this.hasData = true;
                                break;
                            }
                        }
                        if ((this.unReport.length === 0 && this.overReport.length === 0) || !this.hasData) {
                            this.makeToast('info', 'Внимание', 'Данных по таким параметрам нет.');
                        }
                        if (reg.includes('0000')) {
                            this.totalRows();
                        }
                        this.loading = false;
                        this.curTab = 0;
                        this.hasData = false;
                    },
                    (error) => {
                        this.makeToast('danger', 'Ошибка запроса', error.toString());
                        this.loading = false;
                    }
                )
            } else {
                this.makeToast('info', 'Внимание', 'Не все параметры выбраны.');
                this.loading = false;
            }
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },
        totalRows() {
            let regTotalPlan = 0;
            let regTotalPlanYear = 0;
            let regTotalFact = 0;

            let oblTotalPlan = 0;
            let oblTotalPlanYear = 0;
            let oblTotalFact = 0;

            let vzkTotalPlan = 0;
            let vzkTotalPlanYear = 0;
            let vzkTotalFact = 0;

            const vzk = this.unReport.find(x => x.region === '000');
            if (vzk) {
                vzkTotalPlan = vzk.plan;
                vzkTotalPlanYear = vzk.plan_year;
                vzkTotalFact = vzk.fact;
            }
            oblTotalPlanYear -= vzkTotalPlanYear;
            oblTotalPlan -= vzkTotalPlan;

            for (const item of this.unReport) {
                if (!item.region.includes('0101')) {
                    regTotalPlan += item.plan;
                    regTotalPlanYear += item.plan_year;
                    regTotalFact += item.fact;
                } else {
                    oblTotalPlan += item.plan;
                    oblTotalPlanYear += item.plan_year;
                    oblTotalFact += item.fact;
                }
            }
            this.unReport = this.unReport.filter(a => a.region !== '000' && !a.region.includes('0101'));
            this.unReport.sort((a, b) => a.sort_name.localeCompare(b.sort_name));
            const regDelta1 = regTotalFact > 0 ? Math.round((regTotalFact / regTotalPlan) * 1000) / 10 : 0;
            const oblDelta1 = oblTotalFact > 0 ? Math.round((oblTotalFact / oblTotalPlan) * 1000) / 10 : 0;
            this.unReport.push({
                name_ru: 'Итого по районам и городам',
                plan_year: regTotalPlanYear,
                plan: regTotalPlan,
                fact: regTotalFact,
                delta1: regTotalFact > 0 ? Math.round((regTotalFact / regTotalPlan) * 1000) / 10 : 0,
                delta2: regTotalFact - regTotalPlan,
                _rowVariant: 'info'                       
            });
            this.unReport.push({
                name_ru: 'Областной бюджет',
                plan_year: oblTotalPlanYear,
                plan: oblTotalPlan,
                fact: oblTotalFact,
                delta1: oblTotalFact > 0 ? Math.round((oblTotalFact / oblTotalPlan) * 1000) / 10 : 0,
                delta2: oblTotalFact - oblTotalPlan,
                _rowVariant: 'warning'                       
            });
            this.unReport.push({
                name_ru: 'Бюджет области',
                plan_year: oblTotalPlanYear + regTotalPlanYear,
                plan: oblTotalPlan + regTotalPlan,
                fact: oblTotalFact + regTotalFact,
                delta1: Math.round(((oblTotalFact + regTotalFact) / (oblTotalPlan + regTotalPlan)) * 1000) / 10,
                delta2: (oblTotalFact + regTotalFact) - (oblTotalPlan + regTotalPlan),
                _rowVariant: 'primary'                       
            });
        },
        downloadRep() {
            try {
                this.makeToast('info', 'Внимание!', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
                this.loading = true;
                Ax(
                    {
                        url: '/api-py/fulfill_report_income/' + JSON.stringify(this.curParams),
                        method: 'POST',
                        responseType: 'blob'
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.repName + '.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        this.loading = false;
                    },
                    (error) => {
                        this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                        this.loading = false;
                    }
                );
            } catch (error) {
                console.log(error)
                this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
                this.loading = false;
            } finally {
            }
        },
    }
}
</script>

<style>

</style>