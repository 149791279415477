<template>
    <div>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />

        <div v-if="!isOpen">
            <hr>
            <!-------------Периодичность---------------------->
            <b-form-group v-if="repCode.code === 'R542' || repCode.code === 'PR7' || repCode.code === 'income' || repCode.code === 'outcome' || repCode.code === 'control' || repCode.code === 'income-downloader' || repCode.code === 'PR9' || repCode.code === 'FZP'"
                          label="Периодичность">
                <multiselect
                    placeholder="Выберите период"
                    v-model="period"
                    :options="filteredPeriods"
                    value-field="code"
                    track-by="code"
                    label="text"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    @input="handleInput"
                >
                </multiselect>
            </b-form-group>
            <b-form-group v-if="repCode.code === 'dbf' || repCode.code === 'dbf2'" label="Финансовый год">
                <multiselect
                    v-model="FinCurrYear"
                    placeholder="Выберите год"
                    :options="filterYearsFinance()"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false">
                </multiselect>
            </b-form-group>
            <b-form-group v-if="period.code === 1 || period.code === 12 || period.code === 4 || repCode.code === 'PR3'
            || repCode.code === 'PR2' || repCode.code === 'budget-msu' || repCode.code === 'R127'" label="Год">
                <multiselect
                    v-model="currYear"
                    placeholder="Выбрать год"
                    :options="filterYears()"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    ref="filterYear"
                    @input="handleInput">
                </multiselect>
            </b-form-group>
            <b-form-group v-if="period.code === 1 || repCode.code === 'budget-msu' || repCode.code === 'R127'" label="Месяц">
                <multiselect
                    placeholder="Выберите месяц"
                    v-model="selectedMonth"
                    :options="months"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    @input="handleInput"
                />
            </b-form-group>
            <b-form-group v-if="period.code === 4 || repCode.code === 'PR3' || repCode.code === 'PR2'" label="Квартал">
                <multiselect
                    placeholder="Выберите квартал"
                    v-model="selectedQvartal"
                    :options="qvartal"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    @input="getObl"/>
            </b-form-group>
            <b-form-group v-if="period.code === 0 || repCode.code === 'dbf' || repCode.code === 'dbf2' || repCode.code === 'R420' || repCode.code === 'PR4' "
                          label-cols-md="3" label="Дата:">
                <date-picker
                    placeholder="Выберите дату"
                    v-model="firstDateTo"
                    :append-to-body="false"
                    :popup-style="computedPopupStyle"
                    @input="handleInput">
                </date-picker>
            </b-form-group>
            <hr>
            <b-form-group v-if="repCode.code === 'R542' || repCode.code === 'PR7'" label="В разрезе регионов">
                <div class="modal__toggle">
                    <b-form-checkbox v-model="region_switch" switch/>
                </div>
            </b-form-group>
            <!---------------------Като------------------------->
            <b-form-group label="Регион (Область, город, район)">
                <multiselect
                    placeholder="Выберите регион"
                    track-by="code"
                    label="text"
                    v-model="reg"
                    :options="listReg"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    ref="regionRef"
                    @input="() => { LoadForPR7(); LoadForR542(); LoadForR420(); }">
                </multiselect>
            </b-form-group>
            <!---------------------Разрезность------------------------->

            <b-form-group v-if="repCode.code === 'R420' || repCode.code === 'R127'" label="Разрезность">
                <multiselect
                    placeholder="Выберите разрезность"
                    v-model="curCut"
                    :options="cutnessList"
                    value-field="code"
                    track-by="code"
                    label="text"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                >
                </multiselect>
            </b-form-group>
            <!---------------------Администратор множественный выбор------------------------->
            <b-form-group v-if="repCode.code === 'R542'" label="Администратор">
                <multiselect
                    placeholder="-"
                    track-by="text"
                    label="text"
                    v-model="selectabp542"
                    :options="abpList542"
                    :multiple="true"
                    :close-on-select="false"
                    :searchable="true"
                    :show-labels="false"
                    @input="() => {handleSelect(selectabp542, 'abp');}"
                    @close="() => {loadPrgFor542(); loadPprFor542(); loadSpfFor542();}">
                    <template slot="selection" slot-scope="{ values}">
                        <template v-if="values.length >= 1">
                            <span class="multiselect__single" id="multiselect__single_spf">
                              {{ displayText(values, 'abp') }}
                            </span>
                        </template>
                    </template>
                </multiselect>
            </b-form-group>
            <!---------------------Программа множественный выбор------------------------->
            <b-form-group v-if="repCode.code === 'R542'" label="Программа">
                <multiselect
                    placeholder="-"
                    track-by="text"
                    label="text"
                    v-model="selectprg542"
                    :options="prgList542"
                    :multiple="true"
                    :close-on-select="false"
                    :searchable="true"
                    :show-labels="false"
                    @input="() => {handleSelect(selectprg542, 'prg');}"
                    @close="() => {loadPprFor542(); loadSpfFor542();}">
                    <template slot="selection" slot-scope="{ values}">
                        <template v-if="values.length >= 1">
                            <span class="multiselect__single" id="multiselect__single_spf">
                              {{ displayText(values, 'prg') }}
                            </span>
                        </template>
                    </template>
                </multiselect>
            </b-form-group>
            <!---------------------Подрограмма множественный выбор------------------------->
            <b-form-group v-if="repCode.code === 'R542'" label="Подрограмма">
                <multiselect
                    placeholder="-"
                    track-by="text"
                    label="text"
                    v-model="selectppr542"
                    :options="pprList542"
                    :multiple="true"
                    :close-on-select="false"
                    :searchable="true"
                    :show-labels="false"
                    @input="() => {handleSelect(selectppr542, 'ppr');}"
                    @close="loadSpfFor542">
                    <template slot="selection" slot-scope="{ values}">
                        <template v-if="values.length >= 1">
                            <span class="multiselect__single" id="multiselect__single_spf">
                              {{ displayText(values, 'ppr') }}
                            </span>
                        </template>
                    </template>
                </multiselect>
            </b-form-group>
            <!---------------------Специфика множественный выбор------------------------->
            <b-form-group v-if="repCode.code === 'R542'" label="Специфика">
                <multiselect
                    placeholder="-"
                    track-by="text"
                    label="text"
                    v-model="selectspf542"
                    :options="spfList542"
                    :multiple="true"
                    :close-on-select="false"
                    :searchable="true"
                    :show-labels="false"
                    @input="handleSelect(selectspf542, 'spf')">
                    <template slot="selection" slot-scope="{ values}">
                        <template v-if="values.length >= 1">
                            <span class="multiselect__single" id="multiselect__single_spf">
                              {{ displayText(values, 'spf') }}
                            </span>
                        </template>
                    </template>
                </multiselect>
            </b-form-group>
            <!---------------------Источник------------------------->
            <b-form-group v-if="repCode.code === 'R420'" label="Источник">
                <multiselect
                    placeholder="Источник"
                    v-model="dataSource"
                    :options="dataSourceList"
                    value-field="code"
                    track-by="code"
                    label="text"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    @input="handleInput"
                >
                </multiselect>
            </b-form-group>
            <!---------------------Период Отчета-------------------->
            <b-form-group v-if="repCode.code === 'R420'" label="Сравниваемый период">
                <multiselect
                    placeholder="Сравниваемый период"
                    v-model="comparedPeriod"
                    :options="comparedPeriodList"
                    value-field="code"
                    track-by="code"
                    label="text"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                >
                </multiselect>
            </b-form-group>
            <!---------------------Вид программы------------------------->
            <b-form-group v-if="repCode.code === 'R542'" label="Вид программы">
                <multiselect
                    placeholder="Вид программы"
                    v-model="develop"
                    :options="developes"
                    value-field="code"
                    track-by="code"
                    label="text"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                >
                </multiselect>
            </b-form-group>
            <!---------------------АБП------------------------->
            <b-form-group v-if="repCode.code === 'PR7'" label="АБП:">
                <multiselect
                    placeholder="Выберите АБП"
                    track-by="text"
                    label="text"
                    v-model="selectedAbp"
                    :options="abpList"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    @input="loadSpf">
                </multiselect>
            </b-form-group>

            <b-form-group v-if="repCode.code === 'R420'" label="АБП:">
                <multiselect
                    placeholder="Выберите АБП"
                    track-by="text"
                    label="text"
                    v-model="selectedABP420"
                    :options="selectABP420"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    @input="loadGuFor420()">
                </multiselect>
            </b-form-group>
            <!---------------------ГУ-------------------------->
            <b-form-group v-if="repCode.code === 'R420'" label="ГУ:">
                <multiselect
                    placeholder="Выберите ГУ"
                    track-by="text"
                    label="text"
                    v-model="selectedGU420"
                    :options="selectGU420"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false">
                </multiselect>
            </b-form-group>
            <!---------------------ПРГ------------------------->
            <b-form-group v-if="repCode.code === 'PR7'" label="Программа:">
                <multiselect
                    placeholder="Выберите программу"
                    track-by="text"
                    label="text"
                    v-model="selectedPrg"
                    :options="selectedAbp ? selectedAbp.prgList : []"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    @input="loadSpf">
                </multiselect>
            </b-form-group>
            <!---------------------ППР------------------------->
            <b-form-group v-if="repCode.code === 'PR7'" label="Подпрограмма:">
                <multiselect
                    placeholder="Выберите подпрограмму"
                    track-by="text"
                    label="text"
                    v-model="selectedPpr"
                    :options="selectedPrg ? selectedPrg.pprList : []"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    @input="loadSpf"
                >
                </multiselect>
            </b-form-group>
            <!---------------------Специфика------------------------->
            <b-form-group v-if="repCode.code === 'PR7'" label="Специфика:">
                <multiselect
                    placeholder="Выберите специфику"
                    track-by="text"
                    label="text"
                    v-model="selectedSpf"
                    :options="spfList"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                >
                </multiselect>
            </b-form-group>
            <!---------------------ВЗК------------------------->
            <b-form-group v-if="repCode.code === 'R542' || repCode.code === 'PR7'" label="ВЗК">
                <multiselect
                    placeholder="Выберите ВЗК"
                    v-model="selecteds"
                    :options="r_options"
                    value-field="code"
                    track-by="code"
                    label="text"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                >
                </multiselect>
            </b-form-group>
            <!---------------------Единица измерения------------------------->
            <b-form-group v-if="repCode.code === 'income' || repCode.code === 'outcome' || repCode.code === 'income-downloader' || repCode.code === 'PR9'" label="Единица измерения">
                <multiselect
                    v-model="convert"
                    :options="convertList"
                    :allow-empty="false"
                    :show-labels="false">
                </multiselect>
            </b-form-group>
            <!---------------------В разрезе регионов------------------------->
            <b-form-group v-if="(repCode.code === 'R420' && (reg && reg.hasOwnProperty('code') && reg.code.slice(2) === '0000'))" label="В разрезе регионов">
                <b-form-checkbox v-model="is0000Check" switch></b-form-checkbox>
            </b-form-group>
            <b-form-group v-if="(repCode.code === 'R420' || repCode.code === 'R127')" label="Только расхождения">
                <b-form-checkbox v-model="showOnlyErrors" switch></b-form-checkbox>
            </b-form-group>
            <!---------------------тип ФЗП------------------------->
            <b-form-group v-if="repCode.code === 'FZP'">
                <multiselect
                    v-model="typeFZP"
                    :options="listFZP"
                    track-by="text"
                    label="text"
                    :allow-empty="false"
                    :show-labels="false">
                </multiselect>
                <b-checkbox class="mt-2" v-if="typeFZP.code === 1" size="sm" switch v-model="newFZP">Новоприбывшие</b-checkbox>
                <b-checkbox class="mt-2" size="sm" switch v-model="rbFZP">РБ</b-checkbox>
            </b-form-group>
            <!---------------------Страница Сводного отчета МСУ------------------------->
            <b-form-group v-if="repCode.code === 'budget-msu'">
                <multiselect
                    v-model="sheetMsu"
                    :options="listMsu"
                    track-by="text"
                    label="text"
                    :allow-empty="false"
                    :show-labels="false">
                </multiselect>
            </b-form-group>
            <!---------------------Округление------------------------->
            <b-form-group v-if="repCode.code === 'PR4' || repCode.code === 'PR3'">
                <b-checkbox class="mt-2" size="sm" switch v-model="round">Без округления</b-checkbox>
            </b-form-group>
            <!---------------------Без округления------------------------->
            <b-form-group v-if="repCode.code === 'R542'" label="Без округления">
                <div class="modal__toggle">
                    <b-form-checkbox v-model="data_round" switch/>
                </div>
            </b-form-group>
            <hr>
            <div class="filter-actions">
                <b-button style="display: inline-flex" variant="secondary" :disabled="isLoad" @click="paramClear">
                    Сбросить
                </b-button>
                <b-button v-if="repCode.code !== 'dbf' && repCode.code !== 'dbf2'" style="display: inline-block" variant="primary"
                          :disabled="isLoad" @click="loadData">
                    Применить
                </b-button>
                <b-button v-if="repCode.code === 'dbf'" style="display: inline-block" variant="primary"
                          :disabled="isLoad" @click="downloadRep">
                    Скачать
                </b-button>
                <b-button v-if="repCode.code === 'dbf2'" style="display: inline-block" variant="primary"
                          :disabled="isLoad" @click="downloadRepMaild2">
                    Скачать
                </b-button>
            </div>
            <br>
        </div>

    </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";
import DatePicker from "vue2-datepicker";
import {Ax} from "@/utils";
import moment from "moment/moment";
import Multiselect from 'vue-multiselect';
import axios from "axios";

export default {
    name: "filter-all",
    props: {
        repCode: null
    },
    components: {'loading': VueElementLoading, DatePicker, Multiselect},
    data() {
        return {
            obl: null,
            listReg: [],
            listRegion: [],
            curParams: {},
            region: null,
            reg: null,
            dateFrom: null,
            modalForm: false,
            reasonForm: false,
            currYear: new Date().getFullYear(),
            FinCurrYear: new Date().getFullYear(),
            firstDateTo: new Date(
                new Date().getFullYear(),
                this.repCode.code === 'dbf' || this.repCode.code === 'dbf2' ? new Date().getMonth() + 1 : new Date().getMonth(),
                this.repCode.code === 'dbf' || this.repCode.code === 'dbf2' ? 1 : 0
            ),
            // firstDateTo: `${new Date(new Date().getFullYear(), new Date().getMonth(), 0)}`,
            firstDateFrom: `${new Date(new Date().setFullYear(this.firstDateTo), new Date().getMonth() - 12, 1)}`,
            curDate: null,
            dateTo: null,
            isLoad: false,
            isSave: false,
            isOpen: false,
            isNew: false,
            listReports: [],
            arr: [],
            changeRow: false,
            curReport: [],
            curRegion: 0,
            newReport: {id: 0, name_ru: '', name_kk: '', name_en: ''},
            modalDataIsSavable: null,
            loadingF: false,
            loading: false,
            regName: 'Все',
            arrForm: [],
            curRow: [],
            regionForm: null,
            abpList: [],
            prgList: [],
            pprList: [],
            spfList: [],
            abpList542: [],
            prgList542: [],
            pprList542: [],
            spfList542: [],
            selectedAbp: null,
            selectedPrg: null,
            selectedPpr: null,
            selectedSpf: null,
            selectabp542: [],
            selectprg542: [],
            selectppr542: [],
            selectspf542: [],
            selectABP420: [],
            selectGU420: [],
            selectedABP420: null,
            selectedGU420: null,
            region_switch: true,
            data_round: false,
            repdate: '',
            months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь',
                'Октябрь', 'Ноябрь', 'Декабрь'],
            selectedMonth: null,
            selectedQvartal: 'Ⅰ квартал',
            qvartal: ['Ⅰ квартал', 'Ⅱ квартал', 'Ⅲ квартал', 'Ⅳ квартал'],
            curCut: {text: 'Регион', code: 0},
            selecteds: {text: 'с ВЗК', code: 0},
            r_options: [
                {text: 'с ВЗК', code: 0},
                {text: 'без ВЗК', code: 1},
                {text: 'ВЗК', code: 2}
            ],
            developes: [
                {text: 'Текущая', code: 0},
                {text: 'Развития', code: 1},
                {text: 'Все', code: 2}
            ],
            develop: {text: 'Все', code: 2},
            period: '',
            periods: [
                {text: 'Месяц', code: 1},
                {text: 'Год', code: 12},
                {text: 'Квартал', code: 4},
                {text: 'Дата', code: 0}
            ],
            convertList: ['тыс. тенге', 'тенге'],
            convert: 'тыс. тенге',
            dataSource: {text: 'Интеграция', code: 0},
            dataSourceList: [
                {text: 'Интеграция', code: 0},
                {text: 'Загрузчик', code: 1}
            ],
            comparedPeriod: {text: 'На текущий месяц', code: 1},
            comparedPeriodList: [
                {text: 'На текущий месяц', code: 1},
                {text: 'На год', code: 0}
            ],
            is0000Check: false,
            showOnlyErrors: false,
            listFZP: [
                {text: 'Гражданские служащие', code: 1},
                {text: 'Государственные служащие', code: 2},
                {text: 'Правоохранительные органы', code: 3},
            ],
            typeFZP: {text: 'Гражданские служащие', code: 1},
            newFZP: false,
            rbFZP: false,
            sheetMsu: {text: 'Сводная', code: 1},
            listMsu: [
                {text: 'Сводная', code: 1},
                {text: 'Доходы', code: 2},
                {text: 'Затраты', code: 3},
                {text: 'Кол-во сходов и собраний', code: 4}
            ],
            round: false
        };
    },

    async mounted() {
        await this.getObl();
        this.curCut = this.cutnessList[0]
    },

    created() {
        this.firstDateFrom = new Date(this.firstDateFrom);
        this.firstDateTo = new Date(this.firstDateTo);
        this.selectedMonth = this.months[new Date().getMonth()];
        this.$watch('repCode', () => {
            this.firstDateTo = new Date(
                new Date().getFullYear(),
                this.repCode.code === 'dbf' || this.repCode.code === 'dbf2' ? new Date().getMonth() + 1 : new Date().getMonth(),
                this.repCode.code === 'dbf' || this.repCode.code === 'dbf2' ? 1 : 0
            )

            this.curCut = this.cutnessList[0]
        }, {deep: true});
        this.$watch('sheetMsu', () => {
            this.$emit('budgetMsu', this.reg.code, this.reg.name_ru,
                    this.period.code, this.repdate, this.selectedQvartal, this.currYear, this.obl, this.$store.getters.user_uuid, this.sheetMsu);
        });
    },
    computed: {
        buttonVar() {
            return this.isSave ? "success" : "secondary";
        },
        computedPopupStyle() {
            return {
                position: 'absolute',
                top: '100%',
            };
        },
        filteredPeriods() {
            if (this.repCode.code === 'PR7') {
                return this.periods.filter(period => period.text !== 'Год' && period.text !== 'Квартал');
            }
            // if (this.repCode.code === 'PR3' || this.repCode.code === 'PR2') {
            //     return this.periods.filter(period => period.text !== 'Месяц' && period.text !== 'Год' && period.text !== 'Промежуточная');
            // }
            if (this.repCode.code === 'R542') {
                return this.periods.filter(period => period.text !== 'Квартал');
            }
            if (this.repCode.code === 'R420') {
                return this.periods.filter(period => period.text === 'Дата');
            }
            if (this.repCode.code === 'R127') {
                return this.periods.filter(period => period.code === 1)
            }
            if (this.repCode.code === 'income' || this.repCode.code === 'outcome' || this.repCode.code === 'income-downloader') {
                return this.periods.filter(period => period.text !== 'Квартал' && period.text !== 'Год');
            }
            if (this.repCode.code === 'control' || this.repCode.code === 'PR4') {
                return this.periods.filter(period => period.text === 'Дата');
            }
            if (this.repCode.code === 'FZP' || this.repCode.code === 'budget-msu') {
                return this.periods.filter(period => period.text === 'Месяц')
            } else {
                return this.periods;
            }
        },
        cutnessList() {
            if (this.repCode.code === 'R420') {
                return [
                    {text: 'АБП', code: 0},
                    {text: 'ГУ', code: 1},
                    {text: 'Специфика', code: 2}
                ]
            } else  if (this.repCode.code === 'PR9') {
                return [
                    {text: 'Подпрограмма', code: 0},
                    {text: 'Специфика', code: 1}
                ]
            } else  if (this.repCode.code === 'R127') {
                return [
                    {text: 'Регион', code: 0},
                    {text: 'Специфика', code: 1},
                    {text: 'Регион + Специфика', code: 2}
                ]
            } else {
                return [
                    {text: 'Регион', code: 0},
                    {text: 'АБП', code: 1},
                    {text: 'Программа', code: 2},
                    {text: 'Подпрограмма', code: 3},
                    {text: 'Специфика', code: 4}
                ]
            }
        }
    },
    watch: {
        selectabp542(){
            this.selectprg542 = [];
        },
        selectprg542(){
            this.selectppr542 = [];
        },
        selectppr542(){
            this.selectspf542 = [];
        }
    },
    methods: {
        handleSelect(selectedOptions, property) {
            if (selectedOptions.some(option => option[property] === '000')) {
                this['select' + property + '542'] = [this[property + 'List542'][0]];
            } else if (this['select' + property + '542'].some(option => option[property] === '000')) {
                this['select' + property + '542'] = selectedOptions.filter(option => option[property] !== '000');
            } else {
                this['select' + property + '542'] = selectedOptions;
            }
        },
        displayText(value, property){
            if (value.length > 1){
                return value.map(item => item[property]).join(', ')
            }
            else if (value.length === 1){
                return value[0].text
            }
        },
        handleInput() {
            // this.LoadForPR7();
            this.getObl();
        },
        async LoadForPR7() {
            if (this.repCode.code === 'PR7') {
                await this.listLoads();
                await this.loadSpf();
            }
        },
        async LoadForR542() {
            if (this.repCode.code === 'R542') {
                await this.loadAbpFor542();
                await this.loadPrgFor542();
                await this.loadPprFor542();
                await this.loadSpfFor542();
            }
        },
        async LoadForR420() {
            if (this.repCode.code === 'R420') {
                await this.loadAbpFor420();
                await this.loadGuFor420();
            }
        },
        async period_for_all() {
            const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
            if (this.period.code === 0 || this.repCode.code === 'R420') {
                const year = this.firstDateTo.getFullYear();
                const month = String(this.firstDateTo.getMonth() + 1).padStart(2, '0');
                const day = String(this.firstDateTo.getDate()).padStart(2, '0');
                this.repdate = `${year}-${month}-${day}`;
            } else if (this.period.code === 12) {
                this.repdate = `${this.currYear}-12-31`;
            } else if (this.selectedMonth) {
                const month_num = months.indexOf(this.selectedMonth) + 1;
                const last_day = new Date(this.currYear, month_num, 0).getDate();
                this.repdate = `${this.currYear}-${month_num.toString().padStart(2, '0')}-${last_day.toString().padStart(2, '0')}`;
                if (this.repCode.code === 'FZP' || this.repCode.code === 'budget-msu') {
                    this.repdate = `${this.currYear}-${month_num.toString().padStart(2, '0')}-01`;
                }
            }
            if (this.repCode.code === 'PR2' || this.repCode.code === 'PR3') {
                if (this.selectedQvartal === 'Ⅰ квартал') {
                    this.repdate = `${this.currYear}-03-31`;
                } else if (this.selectedQvartal === 'Ⅱ квартал') {
                    this.repdate = `${this.currYear}-06-30`;
                } else if (this.selectedQvartal === 'Ⅲ квартал') {
                    this.repdate = `${this.currYear}-09-30`;
                } else if (this.selectedQvartal === 'Ⅳ квартал') {
                    this.repdate = `${this.currYear}-12-31`;
                }
            }
            return this.repdate;
        },

        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + this.$store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                    });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
            if (this.repCode.code === 'dbf' || this.repCode.code === 'dbf2') {
                try {
                    this.listReg = [];
                    this.listReg.unshift({ text: 'Все', code: this.obl + '0000' });
                    this.reg = this.listReg[0];
                } catch (error) {
                    this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
                }
            } else if (this.repCode.code !== 'dbf' && this.repCode.code !== 'dbf2') {
                const period_date = await this.period_for_all();
                if (period_date !== '') {
                    try {
                        const tableName = this.repCode.code !== 'R420'? this.repCode.code: `R420${this.dataSource.code === 1? "f": ""}`
                        const response = await fetch('/api-py/get_kato_fulfill/' + this.obl + '/' + tableName
                            + '/' + this.$store.getters.user_uuid + '/' + period_date);
                        this.listReg = await response.json();
                        this.reg = this.listReg[0];
                        if (this.repCode.code === 'PR7') {
                            await this.listLoads();
                            await this.loadSpf();
                        }
                        if (this.repCode.code === 'R542') {
                            await this.LoadForR542();
                        }
                        if (this.repCode.code === 'R420') {
                            await this.LoadForR420();
                        }
                    } catch (error) {
                        this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
                    }
                }
            }

        }, // справочник регионов
        // async loadRegions_all() {
        //     try {
        //         const response = await fetch('/api-py/get_kato_all/' + this.obl);
        //         const items = await response.json();
        //         this.listRegion = items.listRegion;
        //         this.region = this.listRegion[0];
        //     } catch (error) {
        //         this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
        //     }
        // },
        async listLoads() {
            if (this.reg !== null && this.repdate !== '') {
                try {
                    this.loadingF = true;
                    this.curParams = {region: this.reg.code, date_to: this.repdate, user_id: this.$store.getters.user_uuid, obl: this.obl};
                    const response = await fetch('/api-py/get-abp-prg-ppr7/' + JSON.stringify(this.curParams));
                    const items = await response.json();
                    await this.loadFilters(new Map(Object.entries(items.abpList)));
                    this.loadingF = false;
                    if (items.abpList.length <= 1){
                        this.makeToast('info', 'Внимание', 'Данных по таким параметрам нет.');
                    }
                } catch (error) {
                    this.makeToast('warning', 'Предупреждение', 'Не все параметры заданы.');
                }
            }
        },
        async loadFilters(map) {
            try {
                this.abpList = [];
                for (const [key, value] of map.entries()) {
                    this.abpList.push(value);
                }
                this.selectedAbp = this.abpList[0];
                this.prgList = this.abpList[0].prgList;
                this.selectedPrg = this.prgList[0];

                this.pprList = this.abpList[0].prgList[0].pprList;
                this.selectedPpr = this.pprList[0];

            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки loadFilters', error.toString());
            }
        },
        async loadSpf() {
            await this.period_for_all();
            if (this.reg !== null && this.repdate !== '') {
                try {
                    this.curParams = {
                        date_to: this.repdate,
                        region: this.reg.code,
                        abp: this.selectedAbp.abp,
                        prg: this.selectedPrg.prg,
                        ppr: this.selectedPpr.ppr,
                        obl: this.obl
                    };
                    const response = await fetch('/api-py/get_spf_list/' + JSON.stringify(this.curParams));
                    const items = await response.json();
                    this.spfList = items.spfList;
                    this.selectedSpf = this.spfList[0];
                } catch (error) {
                    this.makeToast('danger', 'Ошибка запроса специфики', error.toString());
                }
            }
        },
        async loadAbpFor542() {
            await this.period_for_all();
            if (this.reg !== null && this.repdate !== '') {
                try {
                    this.curParams = {date_to: this.repdate, region: this.reg.code, user_id: this.$store.getters.user_uuid, obl: this.obl, cutness: 'abp'};
                    const response = await fetch('/api-py/get_abp_prg_ppr_spf_for_report542/' + JSON.stringify(this.curParams));
                    this.abpList542 = await response.json();
                } catch (error) {
                    this.makeToast('danger', 'Ошибка запроса администратора', error.toString());
                }
            }
        },
        async loadPrgFor542() {
            await this.period_for_all();
            if (this.reg !== null && this.repdate !== '') {
                try {
                    const abpL = this.selectabp542.map(items => items.abp).join(', ');
                    this.curParams = {date_to: this.repdate, region: this.reg.code, user_id: this.$store.getters.user_uuid, obl: this.obl, cutness: 'prg', abpList: abpL};
                    const response = await fetch('/api-py/get_abp_prg_ppr_spf_for_report542/' + JSON.stringify(this.curParams));
                    this.prgList542 = await response.json();
                } catch (error) {
                    this.makeToast('danger', 'Ошибка запроса программы', error.toString());
                }
            }
        },
        async loadPprFor542() {
            await this.period_for_all();
            if (this.reg !== null && this.repdate !== '') {
                try {
                    const abpL = this.selectabp542.map(items => items.abp).join(', ');
                    const prgL = this.selectprg542.map(items => items.prg).join(', ');
                    this.curParams = {date_to: this.repdate, region: this.reg.code, user_id: this.$store.getters.user_uuid, obl: this.obl, cutness: 'ppr', abpList: abpL, prgList: prgL};
                    const response = await fetch('/api-py/get_abp_prg_ppr_spf_for_report542/' + JSON.stringify(this.curParams));
                    this.pprList542 = await response.json();
                } catch (error) {
                    this.makeToast('danger', 'Ошибка запроса программы', error.toString());
                }
            }
        },
        async loadSpfFor542() {
            await this.period_for_all();
            if (this.reg !== null && this.repdate !== '') {
                try {
                    const abpL = this.selectabp542.map(items => items.abp).join(', ');
                    const prgL = this.selectprg542.map(items => items.prg).join(', ');
                    const pprL = this.selectppr542.map(items => items.ppr).join(', ');
                    this.curParams = {date_to: this.repdate, region: this.reg.code, user_id: this.$store.getters.user_uuid, obl: this.obl, cutness: 'spf', abpList: abpL, prgList: prgL, pprList: pprL};
                    const response = await fetch('/api-py/get_abp_prg_ppr_spf_for_report542/' + JSON.stringify(this.curParams));
                    this.spfList542 = await response.json();
                } catch (error) {
                    this.makeToast('danger', 'Ошибка запроса программы', error.toString());
                }
            }
        },
        async loadAbpFor420() {
            const date = await this.period_for_all()
            const req = {
                cutness: this.curCut.code,
                region: this.reg.code,
                repdate: date,
                source: 0,
                user_id: this.$store.state.user.sub,
                compared_period: this.comparedPeriod.code,
                displayZeroReg: this.is0000Check,
                abp: null
            }
            await axios.post('/api-py/fulfillment-application/abp-gu-filter-for-420', req).then(resp => {
                if (resp.status === 200) {
                    this.selectABP420 = resp.data.length ? resp.data : [{
                        abp: null,
                        name_ru: 'Все',
                        name_kk: 'Барлығы'
                    }];

                    const locale = `name_${this.$i18n.locale}`;

                    if (this.selectABP420.length > 1) {
                        this.selectABP420.unshift({
                            abp: null,
                            name_ru: 'Все',
                            name_kk: 'Барлығы'
                        });
                    }

                    this.selectABP420.forEach((abp, i) => {
                        abp.text = abp.abp ? `${abp.abp} - ${abp[locale]}` : abp[locale];
                    });

                    this.selectedABP420 = this.selectABP420[0];
                }
            })
        },
        async loadGuFor420() {
            if (this.selectedABP420 && this.selectedABP420.hasOwnProperty('abp') && this.selectedABP420.abp) {
                const date = await this.period_for_all()
                const req = {
                    cutness: this.curCut.code,
                    region: this.reg.code,
                    repdate: date,
                    source: 0,
                    user_id: this.$store.state.user.sub,
                    compared_period: this.comparedPeriod.code,
                    displayZeroReg: this.is0000Check,
                    abp: this.selectedABP420.abp
                }
                await axios.post('/api-py/fulfillment-application/abp-gu-filter-for-420', req).then(resp => {
                    this.selectGU420 = resp.data.length ? resp.data : [{
                        gu: null,
                        name_ru: 'Все',
                        name_kk: 'Барлығы'
                    }];

                    const locale = `name_${this.$i18n.locale}`;

                    if (this.selectGU420.length > 1) {
                        this.selectGU420.unshift({
                            gu: null,
                            name_ru: 'Все',
                            name_kk: 'Барлығы'
                        });
                    }

                    this.selectGU420.forEach((gu, i) => {
                        gu.text = gu.gu ? `${gu.gu} - ${gu[locale]}` : gu[locale];
                    });

                    this.selectedGU420 = this.selectGU420[0];
                })
            } else {
                this.selectGU420 = [{
                    gu: null,
                    name_ru: 'Все',
                    name_kk: 'Барлығы'
                }]
                this.selectGU420[0].text = this.selectGU420[0][`name_${this.$i18n.locale}`]
                this.selectedGU420 = this.selectGU420[0]
            }

        },
        setNameLang(obj, codeName) {
            let txt = obj['name_' + this.$i18n.locale];
            if (txt === undefined) {
                txt = obj.name_ru;
            }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            return el;
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },
        paramClear() {
            this.firstDateTo = null;
            this.selectedMonth = null;
            this.period = '';
            this.reg = null;
            this.region = null;
            this.currYear = null;
            this.selectedQvartal = null;
            this.selecteds = null;
            this.develop = null;
            this.curCut = null;
            this.selectedAbp = null;
            this.selectedPrg = null;
            this.selectedPpr = null;
            this.selectedSpf = null;
        },
        async getReportList() {
            this.listReports = []
            const params = {repCode: this.repCode}
            const response = await fetch(`/api-py/get-report-data-2/` + JSON.stringify(params));
            this.listReports = await response.json();
        },
        filterYears() {
            const now = new Date();
            const yearNow = now.getFullYear();
            const years = [];
            for (let i = yearNow; i >= yearNow - 10; i--) {
                if (i >= 2020) {
                    years.push(i);
                }
            }
            return years;
        },
        filterYearsFinance() {
            const now = new Date();
            const yearNow = now.getFullYear() + 1;
            const years = [];
            for (let i = yearNow; i >= 2023; i--) {
                years.push(i);
            }
            return years;
        },
        async loadData() {
            if (this.repCode.code === 'PR2') {
                this.$emit('loadApplicationForm2',
                    this.reg.code, this.reg.name_ru,
                    4, this.firstDateTo, this.selectedQvartal, this.currYear, this.obl);
            }
            if (this.repCode.code === 'PR3') {
                this.$emit('loadApplicationForm3',
                    this.reg.code, this.reg.name_ru,
                    4, this.firstDateTo, this.selectedQvartal, this.currYear, this.obl, this.round);
            }
            if (this.repCode.code === 'PR4') {
                this.$emit('loadApplicationForm4',
                    this.reg.code, this.reg.name_ru,
                    this.period.code, this.firstDateTo, this.selectedQvartal, this.currYear, this.obl, this.round);
            }
            if (this.repCode.code === 'PR7') {
                this.$emit('loadData',
                    this.reg.code, this.reg.name_ru,
                    this.period.code, this.firstDateTo, this.selectedMonth, this.currYear,
                    this.selectedAbp.abp, this.selectedPrg.prg, this.selectedPpr.ppr, this.selectedSpf.spf,
                    this.$store.getters.user_uuid, this.obl, this.region_switch, this.selecteds);
            }
            if (this.repCode.code === 'R542') {
                const abpL = this.selectabp542.map(items => items.abp).join(', ');
                const prgL = this.selectprg542.map(items => items.prg).join(', ');
                const pprL = this.selectppr542.map(items => items.ppr).join(', ');
                const spfL = this.selectspf542.map(items => items.spf).join(', ');
                if (abpL === '' && prgL === '' && pprL === '' && spfL === ''){
                    this.makeToast('info', 'Внимание', 'Пожалуйста, выберите необходимые коды ЕБК');
                }
                else { this.$emit('loadReportFormData',
                    this.period.code, this.firstDateTo, this.selectedMonth, this.currYear,
                    this.reg.code, this.reg.name_ru, this.curCut, this.selecteds, this.develop,
                    this.obl, this.$store.getters.user_uuid, this.region_switch, this.data_round,
                    abpL, prgL, pprL, spfL);}
            }
            if (this.repCode.code === 'income' || this.repCode.code === 'income-downloader') {
                this.$emit('reportIncome',
                    this.reg.code, this.reg.name_ru, this.period.code, this.firstDateTo, this.selectedMonth, this.currYear, this.obl, this.convert);
            }
            if (this.repCode.code === 'outcome') {
                this.$emit('reportOutcome',
                    this.reg.code, this.reg.name_ru, this.period.code, this.firstDateTo, this.selectedMonth, this.currYear, this.obl, this.convert);
            }
            if (this.repCode.code === 'control') {
                this.$emit('reportControl',
                    this.reg.code, this.reg.name_ru, this.period.code, this.firstDateTo, this.selectedMonth, this.currYear, this.obl, this.convert);
            }
            if (this.repCode.code === 'R420') {
                this.$emit('loadReportForm420Data', this.reg, this.firstDateTo,
                    this.dataSource, this.curCut, this.comparedPeriod, this.$store.getters.user_uuid, this.selectedABP420, this.selectedGU420, this.is0000Check, this.showOnlyErrors);
            }
            if (this.repCode.code === 'PR9') {
                this.$emit('loadApplication9Data', this.reg, this.firstDateTo, {code: 1}, this.$store.getters.user_uuid, this.period, this.convert);
            }
            if (this.repCode.code === 'FZP') {
                this.$emit('fzp', this.reg.code, this.reg.name_ru,
                    this.period.code, this.repdate, this.selectedQvartal, this.currYear, this.obl, this.$store.getters.user_uuid, this.typeFZP, this.newFZP, this.rbFZP);
            }
            if (this.repCode.code === 'budget-msu') {
                this.$emit('budgetMsu', this.reg.code, this.reg.name_ru,
                    this.period.code, this.repdate, this.selectedQvartal, this.currYear, this.obl, this.$store.getters.user_uuid, this.sheetMsu, this.selectedMonth);
            }
            if (this.repCode.code === 'R127') {
                this.$emit('loadReportForm127Data', this.reg, this.currYear,
                    new Date(this.repdate).getMonth(), this.curCut, this.$store.getters.user_uuid, this.showOnlyErrors);
            }

        },
        downloadRep() {
            const dateString = this.firstDateTo;
            this.firstDateTo = moment(this.firstDateTo).format('YYYY-MM-DD');
            try {
                this.makeToast('info', 'Внимание!', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
                this.isLoad = true;
                Ax({
                        url: `/api/budget-execution-base/dbf?dateString=${this.firstDateTo}&year=${this.FinCurrYear}&region=${this.reg.code}`,
                        method: 'GET',
                        responseType: 'blob'
                    },
                    (data) => {
                        const blob = new Blob([data], {type: 'application/dbf'});
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Maild' + '.dbf');
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        this.makeToast('info', 'Вниминие!', 'Данных по таким параметрам нет.');
                    });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
            } finally {
                this.isLoad = false;
            }
            this.firstDateTo = new Date(moment(dateString).format('YYYY-MM-DD'));
        },
        downloadRepMaild2() {
            const dateString = this.firstDateTo;
            this.firstDateTo = moment(this.firstDateTo).format('YYYY-MM-DD');
            try {
                this.makeToast('info', 'Внимание!', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
                this.isLoad = true;
                Ax({
                        url: `/api/budget-execution-base/dbf2?dateString=${this.firstDateTo}&year=${this.FinCurrYear}&region=${this.reg.code}`,
                        method: 'GET',
                        responseType: 'blob'
                    },
                    (data) => {
                        const blob = new Blob([data], {type: 'application/dbf'});
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Maild(по дате справки)' + '.dbf');
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        this.makeToast('info', 'Вниминие!', 'Данных по таким параметрам нет.');
                    });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
            } finally {
                this.isLoad = false;
            }
            this.firstDateTo = new Date(moment(dateString).format('YYYY-MM-DD'));
        },
        toOpen() {
            this.isOpen = !this.isOpen;
            this.$emit('repOpen', this.isOpen);
        },
        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну
        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab
        inputFixed(item, field, value, digit) {
            this.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
        }, // форматирует введенное значение до digit цифр после запятой


    }
}
</script>
<style>
input[type="date"] {
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 15px;
    border-radius: 5px;
}
</style>

